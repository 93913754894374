import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueGitHubButtons from 'vue-github-buttons'; // Component plugin
import 'vue-github-buttons/dist/vue-github-buttons.css'; // Stylesheet
import VueRouter from 'vue-router';
import VueScrollTo from 'vue-scrollto';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueSimpleMarkdown from 'vue-simple-markdown'
import App from './App.vue';
import router from './router/index';
import { store } from './state/store';

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'

Vue.use(VueSimpleMarkdown)
Vue.use(VueScrollTo);
Vue.use(Buefy);
Vue.use(VueCookies)
Vue.$cookies.config('7d', '', '', true)
Vue.use(PerfectScrollbar)
Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.use(VueGitHubButtons, { useCache: true });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
