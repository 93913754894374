<template>
    <b-navbar-item tag="div">
        <div v-if="githubUsername == null" class="buttons">
            <button class="button is-outlined">
                <b-dropdown
                    :close-on-click="false"
                    position="is-bottom-left"
                    append-to-body
                    trap-focus
                >
                    <div
                        slot="trigger"
                        class="columns is-variable is-1 is-vcentered is-centered"
                    >
                        <div class="column">
                            <a class="has-text-grey is-size-7">
                                Sign in with {{ name }}
                            </a>
                        </div>
                        <div class="column is-narrow">
                            <GithubIcon
                                style="color: white !important"
                            ></GithubIcon>
                        </div>
                    </div>
                    <b-dropdown-item class="no-hover-effect" :focusable="false">
                        <p>
                            By signing in, you are agreeing to our
                            <br />
                            <router-link to="/tos/">
                                Terms of Service
                            </router-link>
                            and
                            <router-link to="/privacy/">
                                Privacy Policy
                            </router-link>
                        </p>

                        <b-checkbox
                            v-model="persistLogin"
                            class="my-2 has-text-grey"
                            :disabled="!useCookies"
                            >Remember me</b-checkbox
                        >
                        <br />
                        <b-checkbox @input="signin"
                            ><strong>Agree</strong> and proceed to Github Login
                        </b-checkbox>
                    </b-dropdown-item>
                </b-dropdown>
            </button>
        </div>
        <div v-else class="columns is-vcentered is-variable is-1">
            <div class="column">
                <p class="is-size-7 has-text-grey">
                    Logged in as
                    <strong>{{ githubUsername }} </strong>
                </p>
            </div>
            <div class="column is-narrow">
                <button
                    @click="logout"
                    class="button is-outlined is-small is-rounded"
                    :disabled="!logoutEnabled"
                >
                    Logout
                </button>
            </div>
        </div>
    </b-navbar-item>
</template>
<script src="oauth.min.js"/>
<script>
import { Auth0Client } from "@auth0/auth0-spa-js";
import { mapGetters } from "vuex";
import GithubIcon from "mdi-vue/Github.vue";
export default {
    components: {
        GithubIcon,
    },
    data() {
        return {
            githubUsername: null,
            persistLogin: false,
            auth0: null,
            logoutEnabled: true,
        };
    },
    computed: {
        ...mapGetters({
            getGithubUsername: "getGithubUsername",
            useCookies: "useCookies",
        }),
    },
    props: {
        name: String,
    },
    async created() {
        let githubOAuth = this.$cookies.get("githubOAuth");
        console.log({githubOAuth})
        let savedInCookies = !!githubOAuth;
        if (savedInCookies) {
            this.$store.dispatch("setGithubLogin", githubOAuth);
        }
        this.auth0 = new Auth0Client({
            domain: "labstreaminglayer.us.auth0.com",
            client_id: "qOmLtz7lOPZPFrzY6OiLaaSAWmoc500j",
            redirect_uri: process.env.VUE_APP_BASE_URL,
            display: "popup",
        });
        this.auth0
            .getTokenSilently()
            .then((token) => {
                this.getUser(token);
            })
            .catch((err) => {});

        // const redirectResult = await this.auth0.handleRedirectCallback();
        // //logged in. you can get the user profile like this:
        // this.auth0.getTokenSilently().then((token) => {
        //     this.auth0.getUser().then((user) => {
        //         console.log(user);
        //     });
        // });
    },
    methods: {
        async logout() {
            this.$store.dispatch("githubLogout");
            this.$cookies.remove("githubOAuth");
            this.auth0.logout({
                returnTo: process.env.VUE_APP_BASE_URL,
            });
        },
        async signin(agreed) {
            if (!agreed) {
                return;
            }
            this.auth0.loginWithPopup().then((token) => {
                this.getUser(token);
            });
            // const redirectResult = await this.auth0.handleRedirectCallback();
            // const user = await this.auth0.getUser();
            // console.log(user);
        },
        async getUser(token) {
            this.auth0.getUser().then((user) => {
                this.logoutDisabled = false;
                console.log({token})
                let githubOAuth = {
                    token: token,
                    username: user.nickname,
                    email: user.email,
                };
                this.$store.dispatch("setGithubLogin", githubOAuth);
                if (this.useCookies && this.persistLogin) {
                    this.$cookies.set(
                        "githubOAuth",
                        JSON.stringify(githubOAuth),
                        { expires: "1M" }
                    );
                }
            });
        },
    },
    watch: {
        getGithubUsername: function (newVal, oldVal) {
            this.githubUsername = newVal;
        },
    },
};
</script>
<style scoped>
a.dropdown-item:hover,
.no-hover-effect {
    background-color: transparent !important;
    color: inherit !important;
}
</style>
