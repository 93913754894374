import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)
export const store = new Vuex.Store({
    state: {
        githubToken: null,
        githubEmail: null,
        githubUsername: null,
        useCookies: true
    },
    mutations: {
        SET_GITHUB_TOKEN(state, oauth_token) {
            state.githubToken = oauth_token
        },
        SET_GITHUB_EMAIL(state, email) {
            state.githubEmail = email
        },
        SET_GITHUB_USERNAME(state, username) {
            state.githubUsername = username
        },
        LOGOUT_FROM_GITHUB(state, username) {
            state.githubToken = null
            state.githubEmail = null
            state.githubUsername = null
        },
        DISABLE_COOKIES(state) {
            state.useCookies = false;
        }
    },
    actions: {
        setGithubLogin({ commit }, {token, email, username}) {
            commit('SET_GITHUB_TOKEN', token)
            commit('SET_GITHUB_EMAIL', email)
            commit('SET_GITHUB_USERNAME', username)
        },
        githubLogout({ commit }) {
            commit('LOGOUT_FROM_GITHUB')
        },
        disableCookies({ commit }) {
            commit('DISABLE_COOKIES')
        }
    },
    getters: {
        getGithubToken: state => {
            return state.githubToken
        },
        getGithubEmail: state => {
            return state.githubEmail
        },
        getGithubUsername: state => {
            return state.githubUsername
        },
        useCookies: state => {
            return state.useCookies
        }
    }
});
