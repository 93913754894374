<template>
    <b-navbar
        class="navbar is-fixed-top"
        :style="navbarScrollStyle"
        role="navigation"
        aria-label="main navigation"
    >
        <template slot="brand" >
          <a class="navbar-item" v-scroll-to="'#hero'">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">Home</b-navbar-item>
          </a>

        </template>

        <template slot="start">
            <a class="navbar-item" v-scroll-to="'#apps'">
                <span class="text mr-2">Apps</span>

                <span class="icon">
                    <AppsIcon></AppsIcon>
                </span>
            </a>
            <a
                class="navbar-item"
                href="https://labstreaminglayer.readthedocs.io/"
                target="_blank"
            >
                <span class="text mr-2">Documentation</span>
                <span class="icon">
                    <BookOpenVariantIcon></BookOpenVariantIcon>
                </span>
            </a>
        </template>
        <template slot="end">
            <b-navbar-dropdown 
                label="Community" 
                hoverable
            >
                <b-navbar-item 
                    href="https://join.slack.com/t/labstreaminglayer/shared_invite/enQtMzA2NjEwNDk0NjA5LTcyYWI4ZDk5OTY5MGI2YWYxNmViNjhkYWRhZTkwYWM0ODY0Y2M0YzdlZDRkZTg1OTUwZDU2M2UwNDgwYzUzNDg"
                    target="_blank">
                    <SlackIcon class="ml-1 mr-3"></SlackIcon>
                    Slack group
                    
                </b-navbar-item>
                <b-navbar-item href="https://forum.labstreaminglayer.org">
                    <ForumIcon class="ml-1 mr-3"></ForumIcon>
                    Forum
                    
                </b-navbar-item>

            </b-navbar-dropdown>
                    
            <a
                class="navbar-item"
                href="https://github.com/sccn/labstreaminglayer"
                target="_blank"
            >
                <span class="text mr-2">GitHub Repo</span>
                <span class="icon">
                    <GithubIcon></GithubIcon>
                </span>
            </a>
            <GithubLogin name="Github"></GithubLogin>
            </div>
        </template>
    </b-navbar>
</template>
<script>
import MagnifyIcon from "mdi-vue/Magnify.vue";
import GithubIcon from "mdi-vue/Github.vue";
import ForumOutlineIcon from "mdi-vue/ForumOutline.vue";
import BookOpenVariantIcon from "mdi-vue/BookOpenVariant.vue";
import AppsIcon from "mdi-vue/Apps.vue";
import SlackIcon from "mdi-vue/Slack.vue";
import ForumIcon from "mdi-vue/Forum.vue";
import CalendarMonthIcon from "mdi-vue/CalendarMonth.vue";
import GithubLogin from "@/components/GithubLogin.vue";

export default {
    name: "NavBar",
    components: {
        GithubIcon,
        ForumOutlineIcon,
        CalendarMonthIcon,
        BookOpenVariantIcon,
        AppsIcon,
        GithubLogin,
        SlackIcon,
        ForumIcon
    },
    data() {
        return {
            navbarScrollStyle: {
                "background-color": "hsl(0, 0%, 96%)",
                "border-radius": "5px",
                height: "4.5rem",
                "box-shadow": "0px 1px 1px #dddeee"
            }
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll(e) {
            this.scrollY = e.target.documentElement.scrollTop;
            this.navbarScrollStyle = {
                "background-color": `hsl(0, 0%, ${96 +
                    Math.min(4, this.scrollY / 100)}%)`,
                "border-bottom-left-radius": `${5 +
                    Math.min(20, this.scrollY / 20)}px`,
                "border-bottom-right-radius": `${5 +
                    Math.min(20, this.scrollY / 20)}px`,
                height: `${4.5 - Math.min(1, this.scrollY / 200)}rem`,
                padding: `0px ${Math.min(30, this.scrollY / 10)}px`,
                "box-shadow": `1px ${1 +
                    Math.min(1, this.scrollY / 200)}px ${1 +
                    Math.min(1, this.scrollY / 50)}px #eee`
            };
        }
    }
};
</script>

<style>
</style>
