export default [
    {
        path: "/",
        name: "home",
        component: () => {
            return import("./view/Home");
        }
    },
    {
        path: "/privacy/",
        name: "privacy",
        component: () => {
            return import("./view/Privacy");
        }
    },
    {
        path: "/tos/",
        name: "tos",
        component: () => {
            return import("./view/ToS");
        }
    },
    {
        path: "/about/",
        name: "about",
        component: () => {
            return import("./view/About");
        }
    }
];
