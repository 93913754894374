<template>
  <div id="app">
    <VueCookieAcceptDecline
        :elementId="'cookieAcceptDecline'"
        :debug="false"
        :position="'bottom-left'"
        :type="'floating'"
        :disableDecline="false"
        :transitionName="'slideFromBottom'"
        :showPostponeButton="false"
        @clicked-decline="cookieClickedDecline"
    >
    </VueCookieAcceptDecline>
    <NavBar></NavBar>
    <RouterView />
    <div style="border-top: 2px solid  #f0f0f0">
      <footer
          style="
        box-shadow: rgb(238, 238, 238) 1px 2px 2px;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-around;"
      >
        <a style="color: black" href="#/about">About</a>
        <a style="color: black" href="#/tos">Terms Of Service</a>
        <a style="color: black" href="#/privacy">Privacy</a>

      </footer>

    </div>
  </div>
</template>

<script>
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import NavBar from "@/components/NavBar";

export default {
  name: "App",
  components: {
    VueCookieAcceptDecline,
    NavBar
  },
  created() {
  },
  methods: {
    cookieClickedDecline() {
      this.$store.dispatch("disableCookies");
    }
  }
};
</script>

<style>
#app {
  font-family: Lato, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  /*-moz-osx-font-smoothing: grayscale;*/
  /* background-color: rgb(243, 243, 243); */
}
</style>
